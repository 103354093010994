import {IDataResponse} from '../../types/cart';
import {FedopsInteraction} from '../../constants';
import {ControllerParams} from '@wix/yoshi-flow-editor';

const storeName = 'Cart';
const dbName = 'CartCache';
const key: string = 'cart';
const TTL = 1000 * 60;

export class CartCacheService {
  // eslint-disable-next-line @typescript-eslint/tslint/config
  private db: IDBDatabase | undefined;
  private hadError = false;

  constructor(private readonly fedops: ControllerParams['flowAPI']['fedops']) {}

  private async getDB(): Promise<IDBDatabase | undefined> {
    if (this.db || this.hadError) {
      return this.db;
    }

    this.db = await new Promise((resolve) => {
      const request = indexedDB.open(dbName, 1);

      request.onupgradeneeded = (event) => {
        const db = (event.target as IDBOpenDBRequest).result;
        db.createObjectStore(storeName, {keyPath: 'key'});
      };

      request.onsuccess = (event) => {
        resolve((event.target as IDBOpenDBRequest).result);
      };

      request.onerror = () => {
        this.hadError = true;
        resolve(undefined);
      };
    });
    return this.db;
  }

  public async get(memberId: string, shouldUseMemberIdAsCacheKey: boolean): Promise<IDataResponse | undefined> {
    this.fedops.interactionStarted(FedopsInteraction.GET_DB);
    const db = await this.getDB();
    this.fedops.interactionEnded(FedopsInteraction.GET_DB);
    if (!db) {
      return;
    }
    this.fedops.interactionStarted(FedopsInteraction.GET_CART_FROM_CACHE_SUCCESS);
    this.fedops.interactionStarted(FedopsInteraction.GET_CART_FROM_CACHE_EMPTY);
    this.fedops.interactionStarted(FedopsInteraction.GET_CART_FROM_CACHE_ERROR);
    this.fedops.interactionStarted(FedopsInteraction.GET_CART_FROM_CACHE_EXPIRED);
    const transaction = db.transaction(storeName, 'readonly');
    const objectStore = transaction.objectStore(storeName);
    const request = objectStore.get(key);

    return new Promise((resolve) => {
      request.onsuccess = (event) => {
        try {
          const result = (event.target as IDBRequest).result;
          if (!result?.value) {
            this.fedops.interactionEnded(FedopsInteraction.GET_CART_FROM_CACHE_EMPTY);
            return resolve(undefined);
          }

          this.fedops.interactionStarted(FedopsInteraction.PARSE_JSON);
          const {cachedMemberId, timestamp, cartData} = getCacheValue(result.value);

          if (shouldUseMemberIdAsCacheKey && cachedMemberId !== memberId) {
            return resolve(undefined);
          }
          this.fedops.interactionEnded(FedopsInteraction.PARSE_JSON);

          if (hasExpired(timestamp)) {
            this.fedops.interactionEnded(FedopsInteraction.GET_CART_FROM_CACHE_EXPIRED);
            return resolve(undefined);
          }

          resolve(cartData);
          this.fedops.interactionEnded(FedopsInteraction.GET_CART_FROM_CACHE_SUCCESS);
        } catch {
          resolve(undefined);
          this.fedops.interactionEnded(FedopsInteraction.GET_CART_FROM_CACHE_ERROR);
        }
      };

      request.onerror = () => {
        resolve(undefined);
        this.fedops.interactionEnded(FedopsInteraction.GET_CART_FROM_CACHE_ERROR);
      };
    });
  }

  public async set(memberId: string, value: IDataResponse, shouldUseMemberIdAsCacheKey: boolean): Promise<void> {
    const db = await this.getDB();
    if (!db) {
      return undefined;
    }
    const transaction = db.transaction(storeName, 'readwrite');
    const objectStore = transaction.objectStore(storeName);
    const valueString = JSON.stringify({
      ...(shouldUseMemberIdAsCacheKey && {cachedMemberId: memberId}),
      cartData: value,
      timestamp: Date.now(),
    });
    const keyValue = {key, value: valueString};
    const request = objectStore.put(keyValue);

    return new Promise((resolve) => {
      request.onsuccess = () => {
        resolve();
      };

      request.onerror = () => {
        resolve(undefined);
      };
    });
  }
}

function getCacheValue(value: string): {cachedMemberId?: string; cartData: IDataResponse; timestamp: number} {
  return JSON.parse(value);
}

function hasExpired(timestamp: number) {
  return Date.now() - timestamp > TTL;
}
